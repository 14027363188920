import {Component} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });

  constructor(private fb: FormBuilder, private auth: AngularFireAuth, private router: Router) {

  }

  login() {
    this.auth.auth
      .signInWithEmailAndPassword(this.form.value.email, this.form.value.password)
      .then(() => this.router.navigateByUrl('/documenten'))
      .catch(err => alert(err));
  }

  resetPassword() {
    if (this.form.value.email) {
      this.auth.auth
        .sendPasswordResetEmail(this.form.value.email)
        .then(() => alert('Verdere acties werden naar uw e-mailadres verstuurd om uw paswoord reset te voltooien.'))
        .catch(err => alert('Error: ' + err));
    } else {
      alert('Uw e-mailadres moet ingevuld zijn om uw wachtwoord te kunnen resetten.');
    }
  }
}
