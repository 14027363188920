import {Component} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {map} from 'rxjs/operators';
import {Event} from '../models/event';
import {environment} from '../../environments/environment';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-evenementen',
  templateUrl: './evenementen.component.html',
  styleUrls: ['./evenementen.component.css']
})
export class EvenementenComponent {

  term: string;

  $upcoming = this.db.collection('events').valueChanges({idField: 'id'}).pipe(
    map((event: Event[]) => event
      .filter(e => new Date(e.date) >= new Date())
      .sort((a, b) => a.date.localeCompare(b.date))
    )
  );

  constructor(private db: AngularFirestore, private auth: AngularFireAuth) {

  }

  generateGoogleMapsUrl(event: Event) {
    const noHtml = event.city.replace('<u>', '').replace('</u>', '');
    return `https://www.google.com/maps/embed/v1/place?q=${event.location},${noHtml}&key=${environment.googleMapsToken}`;
  }

  aanwezig(activiteit: Event) {
    const userid = this.auth.auth.currentUser.uid;
    const afwezigheid = activiteit.afwezig.filter(a => a !== userid);

    this.db.collection('events')
      .doc<Event>(activiteit.id)
      .update({
        aanwezig: [...activiteit.aanwezig.filter(a => a !== userid), userid],
        afwezig: afwezigheid
      });
  }

  afwezig(activiteit: Event) {
    const userid = this.auth.auth.currentUser.uid;
    const aanwezigheid = activiteit.aanwezig.filter(a => a !== userid);

    this.db.collection('events')
      .doc<Event>(activiteit.id)
      .update({
        aanwezig: aanwezigheid,
        afwezig: [...activiteit.afwezig.filter(a => a !== userid), userid]
      });
  }

  isAanwezig(activiteit: Event) {
    return activiteit.aanwezig.includes(this.auth.auth.currentUser.uid);
  }

  isAfwezig(activiteit: Event) {
    return activiteit.afwezig.includes(this.auth.auth.currentUser.uid);
  }

  volzet(activiteit: Event) {
    return !this.isAanwezig(activiteit) && activiteit.limit <= activiteit.aanwezig.length;
  }
}
