<section class="main wrapper style1">
  <header class="major">
    <h2>Albums</h2>
  </header>
  <div class="container">
    <div class="row uniform">
      <div class="12u">
        <input type="text" placeholder="Filter hier op tekst, zoals '2010' of 'ceciliafeest'" [(ngModel)]="term">
      </div>
    </div>

    <hr/>

    <div class="row uniform">
      <div class="3u 6u(2) 12u$(4)" *ngFor="let album of ($albums | async) | filter:term">
        <article class="box post" style="border: 1px solid lightgrey; min-height: 378px;">

          <img class="image fit crop" [src]="album.image | safe" [alt]="album.name"
               onerror="this.onerror=null; this.src='../../images/pic02.jpg'">
          <span><strong>{{album.name}}</strong><br/>{{album.date | date:'dd/MM/yyyy'}}</span>

          <ul class="actions ticket">
            <li><a class="button" [href]="album.url" target="_blank">Foto's bekijken</a></li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</section>
