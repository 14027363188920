import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Bestand} from '../models/bestand';
import {map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-muziek',
  templateUrl: './muziek.component.html',
  styleUrls: ['./muziek.component.css']
})
export class MuziekComponent {

  $drumband: Observable<Bestand[]> = this.db.collection('muziekstukken').valueChanges({idField: 'id'}).pipe(
    map((bestanden: Bestand[]) => bestanden
      .filter(bestand => bestand.group === 'drumband')
      .sort((a, b) => a.name.localeCompare(b.name))
    )
  );

  $harmonie: Observable<Bestand[]> = this.db.collection('muziekstukken').valueChanges({idField: 'id'}).pipe(
    map((bestanden: Bestand[]) => bestanden
      .filter(bestand => bestand.group === 'harmonie')
      .sort((a, b) => a.name.localeCompare(b.name))
    )
  );

  $jeugdorkest: Observable<Bestand[]> = this.db.collection('muziekstukken').valueChanges({idField: 'id'}).pipe(
    map((bestanden: Bestand[]) => bestanden
      .filter(bestand => bestand.group === 'jeugdorkest')
      .sort((a, b) => a.name.localeCompare(b.name))
    )
  );

  constructor(private db: AngularFirestore) {

  }
}
