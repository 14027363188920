import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Bestand} from './models/bestand';
import {map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {AudioService} from './services/audio.service';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  $user = this.auth.user;
  $loggedIn = this.auth.authState;

  $tracks: Observable<Bestand[]> = this.db.collection('muziekstukken').valueChanges().pipe(
    map((bestanden: Bestand[]) => [...bestanden]
      .sort((a, b) => a.name.localeCompare(b.name))
    )
  );

  constructor(private auth: AngularFireAuth, private db: AngularFirestore, private audioService: AudioService) {

  }

  play(track: Bestand) {
    this.audioService.changeTrack(track);
  }
}
