import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './footer/footer.component';
import {environment} from '../environments/environment';
import {registerLocaleData} from '@angular/common';
import localeBE from '@angular/common/locales/nl-BE';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireModule} from '@angular/fire';
import {DocumentenComponent} from './documenten/documenten.component';
import {SafePipe} from './pipes/safe.pipe';
import {AngularFireAnalyticsModule, ScreenTrackingService} from '@angular/fire/analytics';
import {AlbumsComponent} from './albums/albums.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {EvenementenComponent} from './evenementen/evenementen.component';
import {AudioPlayerComponent} from './audio-player/audio-player.component';
import {DurationFormatPipe} from './pipes/duration-format.pipe';
import {LoginComponent} from './login/login.component';
import {AngularFireAuthGuardModule} from '@angular/fire/auth-guard';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {MuziekComponent} from './muziek/muziek.component';
import {OpnamesComponent} from './opnames/opnames.component';
import {NgBytesPipeModule} from 'angular-pipes';
import {TrackTableComponent} from './track-table/track-table.component';
import {ProfielComponent} from './profiel/profiel.component';
import {LogoutComponent} from './logout/logout.component';

registerLocaleData(localeBE);

@NgModule({
  declarations: [
    SafePipe,
    AppComponent,
    FooterComponent,
    DocumentenComponent,
    AlbumsComponent,
    EvenementenComponent,
    AudioPlayerComponent,
    DurationFormatPipe,
    LoginComponent,
    MuziekComponent,
    OpnamesComponent,
    TrackTableComponent,
    ProfielComponent,
    LogoutComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    AngularFirestoreModule,
    AngularFireAnalyticsModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgBytesPipeModule
  ],
  providers: [
    ScreenTrackingService,
    {provide: LOCALE_ID, useValue: 'nl-BE'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
