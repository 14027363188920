<section class="main wrapper style1">
  <header class="major">
    <h2>Profiel</h2>
  </header>
  <div class="container">
    <form role="form" [formGroup]="form" (submit)="update()">
      <div class="row uniform">
        <div class="3u 6u(2) 12u$(4)">
          <label class="label" for="voornaam">Voornaam</label>
          <input [class.invalid]="form.get('voornaam').invalid" id="voornaam" type="text" placeholder="Voornaam"
                 formControlName="voornaam">
        </div>
        <div class="3u 6u(2) 12u$(4)">
          <label class="label" for="familienaam">Familienaam</label>
          <input [class.invalid]="form.get('familienaam').invalid" id="familienaam" type="text"
                 placeholder="Familienaam" formControlName="familienaam">
        </div>
        <div class="3u 6u(2) 12u$(4)">
          <label class="label" for="geboortedatum">Geboortedatum</label>
          <input [class.invalid]="form.get('geboortedatum').invalid" id="geboortedatum" type="date"
                 placeholder="Geboortedatum" formControlName="geboortedatum">
        </div>
        <div class="3u 6u(2) 12u$(4)">
          <label class="label" for="geboorteplaats">Geboorteplaats</label>
          <input [class.invalid]="form.get('geboorteplaats').invalid" id="geboorteplaats" type="text"
                 placeholder="Geboorteplaats" formControlName="geboorteplaats">
        </div>
      </div>
      <div class="row uniform">
        <ul class="actions ticket 4u 6u(2) 12u$(4)">
          <li>
            <label class="label align-left" for="straat">Straat + nummer + bus</label>
            <input [class.invalid]="form.get('straat').invalid" id="straat" type="text"
                   placeholder="Straat + nummer + bus" formControlName="straat">
          </li>
          <li>
            <label class="label align-left" for="postcode">Postcode</label>
            <input [class.invalid]="form.get('postcode').invalid" id="postcode" type="text" placeholder="Postcode"
                   formControlName="postcode">
          </li>
          <li>
            <label class="label align-left" for="gemeente">Gemeente</label>
            <input [class.invalid]="form.get('gemeente').invalid" id="gemeente" type="text" placeholder="Gemeente"
                   formControlName="gemeente">
          </li>
        </ul>

        <ul class="actions ticket 4u 6u(2) 12u$(4)">
          <li>
            <label class="label align-left" for="email">E-mailadres</label>
            <input [class.invalid]="form.get('email').invalid" id="email" type="email" placeholder="E-mailadres"
                   formControlName="email">
          </li>
          <li>
            <label class="label align-left" for="telefoon">Telefoon</label>
            <input [class.invalid]="form.get('telefoon').invalid" id="telefoon" type="text" placeholder="Telefoon"
                   formControlName="telefoon">
          </li>
          <li>
            <label class="label align-left" for="gsm">GSM</label>
            <input [class.invalid]="form.get('gsm').invalid" id="gsm" type="text" placeholder="GSM"
                   formControlName="gsm">
          </li>
        </ul>
      </div>

      <div class="row uniform">
        <ul class="actions ticket 4u 6u(2) 12u$(4)">
          <li>
            <input disabled type="checkbox" id="lid-harmonie" formControlName="lidHarmonie">
            <label class="label align-left" for="lid-harmonie">Lid harmonie</label>
          </li>
          <li>
            <label class="label align-left" for="startjaar-harmonie">Startjaar harmonie</label>
            <input disabled id="startjaar-harmonie" type="number" placeholder="Startjaar harmonie"
                   formControlName="startjaarHarmonie">
          </li>
          <li>
            <label class="label align-left" for="instrument-harmonie">Instrument harmonie</label>
            <input disabled id="instrument-harmonie" type="text" placeholder="Instrument harmonie"
                   formControlName="instrumentHarmonie">
          </li>
        </ul>

        <ul class="actions ticket 4u 6u(2) 12u$(4)">
          <li>
            <input disabled type="checkbox" id="lid-drumband" formControlName="lidDrumband">
            <label class="label align-left" for="lid-drumband">Lid drumband</label>
          </li>
          <li>
            <label class="label align-left" for="startjaar-drumband">Startjaar drumband</label>
            <input disabled id="startjaar-drumband" type="number" placeholder="Startjaar drumband"
                   formControlName="startjaarDrumband">
          </li>
          <li>
            <label class="label align-left" for="instrument-drumband">Instrument drumband</label>
            <input disabled id="instrument-drumband" type="text" placeholder="Instrument drumband"
                   formControlName="instrumentDrumband">
          </li>
        </ul>

        <ul class="actions ticket 4u 6u(2) 12u$(4)">
          <li>
            <input disabled type="checkbox" id="lid-jeugdorkest" formControlName="lidJeugdorkest">
            <label class="label align-left" for="lid-jeugdorkest">Lid jeugdorkest</label>
          </li>
          <li>
            <label class="label align-left" for="startjaar-jeugdorkest">Startjaar jeugdorkest</label>
            <input disabled id="startjaar-jeugdorkest" type="number" placeholder="Startjaar jeugdorkest"
                   formControlName="startjaarJeugdorkest">
          </li>
          <li>
            <label class="label align-left" for="instrument-jeugdorkest">Instrument jeugdorkest</label>
            <input disabled id="instrument-jeugdorkest" type="text" placeholder="Instrument jeugdorkest"
                   formControlName="instrumentJeugdorkest">
          </li>
        </ul>
      </div>
      <div class="row uniform">
        <div class="12u">
          <label class="label" for="andere">Reeds gespeeld in andere muziekverenigingen?</label>
          <input disabled id="andere" type="text" placeholder="Andere verenigingen" formControlName="andere">
        </div>
      </div>
      <div class="row uniform">
        <div class="12u">
          <button [disabled]="form.invalid" class="button 3u" type="submit">Aanpassen</button>
        </div>
      </div>
    </form>
  </div>
</section>
