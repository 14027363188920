<section class="main wrapper style1">
  <header class="major">
    <h2>Opkomende activiteiten</h2>
  </header>
  <div class="container">
    <div class="row uniform">
      <div class="12u">
        <input type="text" placeholder="Filter hier op tekst, zoals 'drumband' of 'harmonie'" [(ngModel)]="term">
      </div>
    </div>

    <hr/>

    <div class="row">
      <table aria-hidden="true">
        <thead>
        <tr>
          <th id="event-tijdstip">Tijdstip</th>
          <th id="event-naam">Naam</th>
          <th id="event-locatie">Locatie</th>
          <th id="event-gemeente">Gemeente</th>
          <th id="event-acties">Acties</th>
          <th id="event-aanwezigheid">Aanwezigheid</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let activiteit of ($upcoming | async) | filter:term">
          <td>{{activiteit.date | date:'dd/MM/yyyy'}} - {{activiteit.time}}</td>
          <td>{{activiteit.name}}</td>
          <td>{{activiteit.location}}</td>
          <td [innerHTML]="activiteit.city"></td>
          <td>
            <ul class="icons" style="margin-bottom: 0;">
              <li>
                <a [href]="generateGoogleMapsUrl(activiteit)"
                   target="_blank"
                   class="icon brands fa-map-marker" style="cursor: pointer;">
                  <span class="label">Map</span>
                </a>
              </li>
              <li *ngIf="activiteit.facebook">
                <a [href]="activiteit.facebook"
                   target="_blank"
                   class="icon brands fa-facebook" style="cursor: pointer;">
                  <span class="label">Facebook</span>
                </a>
              </li>
            </ul>
          </td>
          <td>
            <ul class="icons" style="margin-bottom: 0;">
              <li *ngIf="!volzet(activiteit)">
                <a class="icon brands fa-check aanwezig" [class.aanwezig]="isAanwezig(activiteit)"
                   style="cursor: pointer;" (click)="aanwezig(activiteit)">
                  <span class="label">Aanwezig</span>
                </a>
              </li>
              <li *ngIf="!volzet(activiteit)">
                <a class="icon brands fa-close" [class.afwezig]="isAfwezig(activiteit)"
                   style="cursor: pointer;" (click)="afwezig(activiteit)">
                  <span class="label">Afwezig</span>
                </a>
              </li>
              <li *ngIf="volzet(activiteit)">
                volzet
              </li>
              <li>
                <span style="font-size: 10px"> ({{activiteit.aanwezig.length}}/{{activiteit.limit}})</span>
              </li>
            </ul>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
