import {Component} from '@angular/core';
import {GoogleAnalyticsExtension} from '../extensions/google-analytics.ext';
import {AngularFireAnalytics} from '@angular/fire/analytics';

declare function require(moduleName: string): any;

const {version: appVersion} = require('../../../package.json');

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent extends GoogleAnalyticsExtension {

  public appVersion;

  constructor(protected analytics: AngularFireAnalytics) {
    super(analytics);
    this.appVersion = appVersion;
  }
}
