import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Profiel} from '../models/profiel';
import {detailedDiff} from 'deep-object-diff';

@Component({
  selector: 'app-profiel',
  templateUrl: './profiel.component.html',
  styleUrls: ['./profiel.component.css']
})
export class ProfielComponent implements OnInit {

  private oldData;

  form = this.fb.group({
    voornaam: ['', Validators.required],
    familienaam: ['', Validators.required],
    geboortedatum: ['', Validators.required],
    geboorteplaats: [''],
    straat: ['', Validators.required],
    postcode: ['', Validators.required],
    gemeente: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    telefoon: [''],
    gsm: [''],
    lidHarmonie: [false],
    startjaarHarmonie: [null],
    instrumentHarmonie: [''],
    lidJeugdorkest: [false],
    startjaarJeugdorkest: [null],
    instrumentJeugdorkest: [''],
    lidDrumband: [false],
    startjaarDrumband: [null],
    instrumentDrumband: [''],
    andere: [''],
    role: ['LID']
  });

  constructor(private fb: FormBuilder, private auth: AngularFireAuth, private db: AngularFirestore) {
  }

  ngOnInit(): void {
    this.db.collection('profiel')
      .doc(this.auth.auth.currentUser.uid)
      .valueChanges()
      .subscribe((data: any) => {
        this.form.patchValue(data);
        this.oldData = data;
      });
  }

  update() {
    const profile: Profiel = this.form.getRawValue();
    this.db.collection('profiel')
      .doc(this.auth.auth.currentUser.uid)
      .set(profile)
      .then(() => alert('Uw gegevens werden bijgewerkt.'))
      .catch(err => alert(err));

    this.db.collection('profiel-changes')
      .doc(`${profile.voornaam}-${profile.familienaam}-${new Date().toISOString()}`)
      .set(detailedDiff(this.oldData, profile))
      .catch(err => alert(err));

    this.auth.auth.currentUser.updateProfile({
      displayName: `${profile.voornaam} ${profile.familienaam}`
    });

    // TODO
    //  - save email
  }
}
