import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {Bestand} from '../models/bestand';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-documenten',
  templateUrl: './documenten.component.html',
  styleUrls: ['./documenten.component.css']
})
export class DocumentenComponent {

  $documents: Observable<Bestand[]> = this.db.collection('documenten').valueChanges({idField: 'id'}).pipe(
    map((bestanden: Bestand[]) => [...bestanden]
      .sort((a, b) => b.upload.localeCompare(a.upload))
    )
  );

  constructor(private db: AngularFirestore) {

  }
}
