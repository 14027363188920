<div class="row">
  <h2>{{title}}</h2>
  <table aria-hidden="true">
    <thead>
    <tr>
      <th id="track-concert">Concert</th>
      <th id="track-naam">Naam</th>
      <th id="track-extensie">Extensie</th>
      <th id="track-grootte">Grootte</th>
      <th id="track-acties">Acties</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let track of $tracks | async">
      <td>{{track.concert}}</td>
      <td>{{track.name}}</td>
      <td>{{track.extension}}</td>
      <td>{{track.size | bytes}}</td>
      <td>
        <ul class="icons" style="margin-bottom: 0;">
          <li>
            <a (click)="play(track)"
               class="icon brands fa-play" style="cursor: pointer;">
              <span class="label">Play</span>
            </a>
          </li>
          <li>
            <a [href]="track.url" target="_blank" class="icon brands fa-download" style="cursor: pointer;">
              <span class="label">Download</span>
            </a>
          </li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>
</div>
