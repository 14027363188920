import {AngularFireAnalytics} from '@angular/fire/analytics';

export class GoogleAnalyticsExtension {

  constructor(protected analytics: AngularFireAnalytics) {

  }

  submitEvent(category, action, value = null) {
    this.analytics.logEvent(category, action, value);
  }
}
