<section class="main wrapper style1">
  <header class="major">
    <h2>Opnames</h2>
  </header>
  <div class="container">
    <app-track-table [title]="'Drumband'" [$tracks]="$drumband"></app-track-table>
    <app-track-table [title]="'Harmonie'" [$tracks]="$harmonie"></app-track-table>
    <app-track-table [title]="'Jeugdorkest'" [$tracks]="$jeugdorkest"></app-track-table>
  </div>
</section>
