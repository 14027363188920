import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Album} from '../models/album';
import {map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'app-albums',
  templateUrl: './albums.component.html',
  styleUrls: ['./albums.component.css']
})
export class AlbumsComponent {

  term: string;

  $albums: Observable<Album[]> = this.db.collection('fotos').valueChanges({idField: 'id'}).pipe(
    map((albums: Album[]) => [...albums]
      .sort((a, b) => b.date.localeCompare(a.date))
    )
  );

  constructor(private db: AngularFirestore) {

  }
}
