import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Bestand} from '../models/bestand';

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  onTrackChange = new Subject<Bestand>();

  changeTrack(bestand: Bestand) {
    this.onTrackChange.next(bestand);
  }
}
