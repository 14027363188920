import {Component, OnDestroy} from '@angular/core';
import {Howl} from 'howler';
import {AudioService} from '../services/audio.service';
import {interval, Subscription} from 'rxjs';
import {Bestand} from '../models/bestand';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css']
})
export class AudioPlayerComponent implements OnDestroy {

  sound: Howl;
  currentTime;
  bestand: Bestand;

  private audioSubscription: Subscription;
  private timerSubscription: Subscription;

  constructor(audioService: AudioService) {
    this.audioSubscription = audioService.onTrackChange.subscribe((bestand: Bestand) => {
        if (this.sound) {
          this.sound.stop();
        }
        this.bestand = bestand;

        this.sound = new Howl({
          src: [bestand.url],
          html5: true
        });
        this.sound.play();
      }
    );

    this.timerSubscription = interval(500).subscribe(() => this.currentTime = this.sound ? this.sound.seek() : 0);
  }

  play() {
    this.sound.play();
  }

  pause() {
    this.sound.pause();
  }

  mute() {
    this.sound.mute(true);
  }

  unmute() {
    this.sound.mute(false);
  }

  seek(time) {
    this.sound.seek(time);
    this.currentTime = time;
  }

  ngOnDestroy(): void {
    this.audioSubscription.unsubscribe();
    this.timerSubscription.unsubscribe();
  }
}
