<section class="main wrapper style1">
  <header class="major">
    <h2>Documenten</h2>
  </header>
  <div class="container">
    <div class="row uniform">
      <div class="3u 6u(2) 12u$(4)" *ngFor="let document of $documents | async">
        <article class="box post" style="border: 1px solid lightgrey; min-height: 350px;">
          <embed [src]="document.url | safe" [width]="'100%'" class="image fit crop" [type]="document.type">
          <span><strong>{{document.name}}</strong><br/>{{document.upload | date:'dd/MM/yyyy'}}</span>

          <ul class="actions ticket">
            <li><a class="button" [href]="document.url" target="_blank">bekijken</a></li>
          </ul>
        </article>
      </div>
    </div>
  </div>
</section>
