<a class="icon brands fa-play" style="cursor: pointer;" (click)="play()" *ngIf="!sound?.playing()">
  <span class="label">play</span>
</a>
<a class="icon brands fa-pause" style="cursor: pointer;" (click)="pause()" *ngIf="sound?.playing()">
  <span class="label">pause</span>
</a>

{{currentTime | durationFormat: 's':'mmss'}}

<input type="range" [min]="0" [value]="currentTime" #track [max]="sound?.duration()" (change)="seek(track.value)">

{{sound?.duration() | durationFormat: 's':'mmss'}}&nbsp;&nbsp;

<a class="icon brands fa-volume-off" style="cursor: pointer;" (click)="mute()" *ngIf="!sound?.mute()">
  <span class="label">mute</span>
</a>

<a class="icon brands fa-volume-up" style="cursor: pointer;" (click)="unmute()" *ngIf="sound?.mute()">
  <span class="label">mute</span>
</a>

{{bestand?.name || 'Kies een audiobestand'}}&nbsp;&nbsp;
