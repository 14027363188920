import {Component, Input} from '@angular/core';
import {Bestand} from '../models/bestand';
import {Observable} from 'rxjs';
import {AudioService} from '../services/audio.service';

@Component({
  selector: 'app-track-table',
  templateUrl: './track-table.component.html',
  styleUrls: ['./track-table.component.css']
})
export class TrackTableComponent {

  @Input() title: string;
  @Input() $tracks: Observable<Bestand[]>;

  constructor(private audioService: AudioService) {
  }

  play(track: Bestand) {
    this.audioService.changeTrack(track);
  }
}
