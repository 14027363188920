<section class="main wrapper style2">
  <header class="major">
    <h2>Ledenportaal - Cecilia Heist</h2>
  </header>
  <div class="container">
    <div class="row uniform">
      <div class="4u"></div>
      <div class="4u">
        Aan het uitloggen...
      </div>
      <div class="4u"></div>
    </div>
  </div>
</section>
