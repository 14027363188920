<section class="main wrapper style2">
  <header class="major">
    <h2>Ledenportaal - Cecilia Heist</h2>
  </header>
  <div class="container">
    <div class="row uniform">
      <div class="4u"></div>
      <div class="4u">
        <form role="form" [formGroup]="form" (submit)="login()">
          <input type="email" placeholder="E-mailadres" formControlName="email">
          <br/>
          <input type="password" placeholder="Wachtwoord" formControlName="password">
          <br/>
          <ul class="actions ticket">
            <li>
              <button [disabled]="form.invalid" class="button" type="submit">Inloggen</button>
            </li>
            <li>
              <a (click)="resetPassword()" style="cursor: pointer">wachtwoord resetten</a>
            </li>
          </ul>
        </form>
      </div>
      <div class="4u"></div>
    </div>
  </div>
</section>
