<header id="header" class="skel-layers-fixed">
  <h1><a routerLink="/">Portaal Sint-Cecilia Heist</a></h1>
  <nav id="nav" [style]="($loggedIn | async) ? 'display: block' : 'display: none'">
    <ul>
      <li>
        <app-audio-player></app-audio-player>
        <a class="icon fa-angle-down"></a>
        <ul>
          <li *ngFor="let track of $tracks | async" (click)="play(track)"><a>{{track.name}}</a></li>
        </ul>
      </li>
      <li></li>
      <li></li>
      <li><a routerLink="/activiteiten" routerLinkActive="active">Activiteiten</a></li>
      <li><a routerLink="/documenten" routerLinkActive="active">Documenten</a></li>
      <li><a routerLink="/muziek" routerLinkActive="active">Muziek</a></li>
      <li><a routerLink="/opnames" routerLinkActive="active">Opnames</a></li>
      <li><a routerLink="/albums" routerLinkActive="active">Albums</a></li>
      <li></li>
      <li></li>
      <li *ngIf="($user | async) as user">
        <a routerLink="/profiel" routerLinkActive="active">
          <span>{{user?.displayName || user?.email}}</span>
        </a>
      </li>
      <li>
        <a routerLink="/logout" routerLinkActive="active"
           class="icon brands fa-sign-out" style="cursor: pointer;">
          <span class="label">Uitloggen</span>
        </a>
      </li>
    </ul>
  </nav>
</header>

<router-outlet></router-outlet>

<app-footer></app-footer>
