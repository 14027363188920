import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DocumentenComponent} from './documenten/documenten.component';
import {AlbumsComponent} from './albums/albums.component';
import {EvenementenComponent} from './evenementen/evenementen.component';
import {AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/auth-guard';
import {LoginComponent} from './login/login.component';
import {MuziekComponent} from './muziek/muziek.component';
import {OpnamesComponent} from './opnames/opnames.component';
import {ProfielComponent} from './profiel/profiel.component';
import {LogoutComponent} from './logout/logout.component';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToDocumenten = () => redirectLoggedInTo(['documenten']);

const routes: Routes = [
  {
    path: '',
    redirectTo: '/documenten',
    pathMatch: 'full'
  }, {
    path: 'documenten',
    component: DocumentenComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'albums',
    component: AlbumsComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'muziek',
    component: MuziekComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'opnames',
    component: OpnamesComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'activiteiten',
    component: EvenementenComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'profiel',
    component: ProfielComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }, {
    path: 'login',
    component: LoginComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectLoggedInToDocumenten}
  }, {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AngularFireAuthGuard], data: {authGuardPipe: redirectUnauthorizedToLogin}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
